import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const displayPages: (number | string)[] = []; // Explicitly type as an array of numbers or strings

    // Always show the first page
    displayPages.push(1);

    // Add ellipsis if needed after the first page
    if (currentPage > 3) {
        displayPages.push('...');
    }

    // Show surrounding pages around the current page
    for (let page = Math.max(2, currentPage - 1); page <= Math.min(totalPages - 1, currentPage + 1); page++) {
        displayPages.push(page);
    }

    // Add ellipsis if needed before the last page
    if (currentPage < totalPages - 2) {
        displayPages.push('...');
    }

    // Always show the last page
    if (totalPages > 1) {
        displayPages.push(totalPages);
    }

    return (
        <div className="sticky bottom-10 z-1 bg-transparent flex justify-center mt-4">
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 mx-1 border border-gray-400 bg-white rounded-3xl shadow-lg"
            >
                Previous
            </button>

            {displayPages.map((page, index) => (
                <button
                    key={index}
                    onClick={() => typeof page === 'number' && handlePageChange(page)}
                    className={`w-12 px-2 py-1 mx-1 border border-gray-400 text-md rounded-3xl shadow-lg opacity-70 font-bold  ${page === currentPage
                        ? 'bg-blue-500 text-white font-bold' // Change text color for selected page
                        : 'bg-white text-black' // Default styles for other pages
                        }`}
                >
                    {page}
                </button>
            ))}

            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-2 mx-1 border border-gray-400 bg-white rounded-3xl shadow-lg"
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;