import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Header from './components/Header';
import Footer from './components/Footer';
import CsvUploader from './components/CSVUploader';
// import MeteorPreview from './pages/MeteorPreview';
import ProductPage from './pages/ProductPage'; // duplicated from ProductModal
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <AuthProvider>
      <HelmetProvider>

        <Router>
          <div className="flex flex-col min-h-screen ">
            <HeaderWrapper /> {/* Use HeaderWrapper to conditionally render Header */}

            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />

                {/* <Route path="/product/:id" element={<ProductPage />} /> */}
                {/* New route for product details */}
                <Route path="/product/:productId/:productName?" element={<ProductPage />} />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<PasswordReset />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/csv-uploader" element={<CsvUploader />} />

                {/* <Route path="/meteor" element={<MeteorPreview />} /> */}
              </Routes>
            </main>
            <Footer />
          </div>
        </Router >
      </HelmetProvider>

    </AuthProvider >
  );
};

const HeaderWrapper = () => {
  const location = useLocation();

  // Hide the header if the path is the root
  const shouldHideHeader = location.pathname === '/';

  return shouldHideHeader ? null : <Header />;
};

export default App;
