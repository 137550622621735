import React , { useEffect } from 'react';

const Footer = () => {

    const fqdn = window.location.hostname;  // Get the current host FQDN

    useEffect(() => {
        const currentHost = window.location.hostname;

        // URL of the external CSS
        const externalCssUrl = 'https://u8.my/excss/excss.css';

        // List of domains where you want to load the external CSS
        const productionDomains = ['price.ka.my', 'next.ka.my', 't1.u8.my'];
        const developmentDomains = ['localhost', '127.0.0.1'];

        // Check if we're in production and on one of the specified domains
        if (process.env.NODE_ENV === 'development' && developmentDomains.includes(currentHost)) {
            // Create a link element
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = externalCssUrl;
            link.type = 'text/css';

            // Append the link element to the head
            document.head.appendChild(link);

            // Cleanup: remove the link when the component is unmounted
            return () => {
                document.head.removeChild(link);
            };
        }

        if (process.env.NODE_ENV === 'production' && productionDomains.includes(currentHost)) {
            // Create a link element
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = externalCssUrl;
            link.type = 'text/css';

            // Append the link element to the head
            document.head.appendChild(link);

            // Cleanup: remove the link when the component is unmounted
            return () => {
                document.head.removeChild(link);
            };
        }
    }, []); // Empty dependency array means this runs once on mount

    return (
        <footer className="footer z-50 sticky bottom-0  bg-gray-800 text-white text-xs text-center p-2">
            <p style={{ fontSize: '10px', color: 'white' }}>&copy; {new Date().getFullYear()}
                &nbsp;
                {process.env.REACT_APP_COMPANY_NAME}
                {/* {process.env.REACT_APP_DOMAIN_NAME}. */}
                &nbsp;
                {fqdn}
                &nbsp;
                All Rights Reserved. Developed by Boyd Chung</p>
        </footer>
    );
};

export default Footer;
