// src/types.ts



export interface Category {
    id: number;
    cat_name: string;
    cat_prod_qty: number;
}

// /src/utils/types.ts
export interface Brand {
    id: number;
    cat_name: string;
    brand_prod_qty: number;
}

export interface Media {
    id: number;
    original_url: string;
    preview_url: string;
    // Add other properties from media if needed
};

export interface Product {
    id: number;
    media: Media[]; // Update this to be an array of Media objects
    original_url: string;
    preview_url: string;
    product_code: string;
    product_name: string;
    product_desc: string;
    product_value: number;
    brand: number;
    MTPrice: number;
    MTPriceArray: { price: string; cat_name: string }[]; // Updated structure
    product_cat: number;
    cat_name: string;
    brand_name: string;
    product_link: string;
    details: string;
    warranty: string;
    stock: number;
    restock: number;
    restock_at: string;
    // Add any other properties your product may have
};

export const displayPrice = (price: any) => {
    // Convert price to a number if it's not already
    const numericPrice = typeof price === 'number' ? price : parseFloat(price);

    // Check if numericPrice is valid
    if (!isNaN(numericPrice)) {
        return numericPrice.toLocaleString('en-US', { minimumFractionDigits: 2 }); // Format to 2 decimal places
    }

    return 'N/A'; // Return a fallback value if price is invalid
};

export const formatProductNameForUrl = (name: string) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')  // Replace non-alphanumeric characters with hyphens
        .replace(/-+$/, '');          // Remove trailing hyphens
};
