// /src/components/BrandList.tsx
import React, { } from 'react';
import { Brand } from '../utils/types';

interface BrandListProps {
    brands: Brand[];
    setSelectedBrand: (brand: Brand) => void;
    selectedBrand: Brand | null;
}

const BrandList: React.FC<BrandListProps> = ({ brands, setSelectedBrand, selectedBrand }) => {

    return (
        <ul className='fixed top-10 md:top-10 left-0 z-20 bg-purple-100 w-1/2 md:w-2/4 overflow-y-scroll h-[80vh] mt-0 pb-10'>
            {brands.map(brand => (
                <li key={brand.id} className={`border-b border-b-1 border-b-gray-200 
                            ${selectedBrand?.id === brand.id ? 'bg-purple-200 py-1 font-semibold text-blue-600 shadow-lg' : ''}`}>
                    <button onClick={() => setSelectedBrand(brand)} className="break-words m-1 p-1  text-sm md:text-sm md:text-xs text-left hover:underline hover:underline-offset-8 decoration-purple-400">
                        {brand.cat_name}&nbsp;
                        <span className="text-xs text-gray-400">({brand.brand_prod_qty})</span>
                    </button>
                </li>
            ))}
        </ul>
    );
};

export default BrandList;
