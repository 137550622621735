import React, { useState } from 'react';
import { uploadCsv } from '../utils/api'; // Import the uploadCsv function

const CsvUploader = () => {
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a file to upload.');
            return;
        }

        setUploading(true);
        setMessage('');

        try {
            const response = await uploadCsv(file);
            setMessage(`Upload successful: ${response.message}`);
        } catch (error) {
            if (error instanceof Error) {
                setMessage(`Upload failed: ${error.message}`);
            } else {
                setMessage('Upload failed: An unknown error occurred.');
            }
        } finally {
            setUploading(false);
            setFile(null); // Clear the file after upload
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-sm">
                <h2 className="mb-4 text-xl font-bold text-center">Upload CSV File</h2>
                <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    className="border border-gray-300 p-3 mb-4 w-full rounded"
                />
                <button
                    onClick={handleUpload}
                    disabled={uploading}
                    className={`bg-blue-500 text-white p-3 w-full rounded ${uploading ? 'opacity-50' : ''}`}
                >
                    {uploading ? 'Processing...' : 'Upload CSV'}
                </button>
                {message && <p className="mt-2 text-center text-red-500">{message}</p>}
            </div>
        </div>
    );
};

export default CsvUploader;
