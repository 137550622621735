// /src/components/CategoryList.tsx
import React from 'react';
import { Category } from '../utils/types';

interface CategoryListProps {
    categories: Category[];
    setSelectedCategory: (category: Category) => void;
    selectedCategory: Category | null; // Add this line
}

const CategoryList: React.FC<CategoryListProps> = ({ categories, setSelectedCategory, selectedCategory }) => {
    return (
        <ul className='fixed top-10 md:top-10 left-0 z-20 bg-orange-100 w-1/2 md:w-2/4 overflow-y-scroll h-[80vh] mt-0 pb-10'>
            {categories.map(category => (
                <li key={category.id} className={`border-b border-b-1 border-b-gray-200 
                            ${selectedCategory?.id === category.id ? 'bg-orange-200 py-1 font-semibold text-blue-600 shadow-lg' : ''}`}>
                    <button onClick={() => setSelectedCategory(category)} className={`break-words m-1 p-1  text-sm md:text-sm md:text-sm md:text-xs text-left hover:underline hover:underline-offset-8 decoration-orange-400`}>
                        {category.cat_name}&nbsp;
                        <span className='text-xs text-gray-400'>({category.cat_prod_qty})</span>
                    </button>
                </li>
            ))}
        </ul>
    );
};

export default CategoryList;
