// src / components / ProductDetails.tsx
import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { Product } from '../utils/types';

interface ProductDetailsProps {
    product: Product;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ product }) => {
    const sanitizedHtml = product.details;

    // Configure DOMPurify to allow iframes only for specific YouTube domains
    const cleanHtml = DOMPurify.sanitize(sanitizedHtml, {
        ADD_TAGS: ['iframe', 'img'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src', 'width', 'height', 'alt'],
        // Allow unrestricted URIs for images but restrict iframe sources to YouTube domains
        FORBID_TAGS: [''],
        ALLOWED_URI_REGEXP: /^(https?:\/\/(www\.)?(youtube\.com|youtube-nocookie\.com)\/embed\/|https?:\/\/.*\.(jpg|jpeg|png|webp|avif|gif|svg)|http?:\/\/.*\.(jpg|jpeg|png|webp|avif|gif|svg))/
    });

    if (!sanitizedHtml) return null;

    return (
        <div
            className='inDetails pb-5 mb-36 text-md font-normal break-all'
            dangerouslySetInnerHTML={{ __html: cleanHtml }}
        />
    );
};

export default ProductDetails;
